import {Form} from "./form";
import {detectDevice} from "./device-detector";

const ORDER_MODAL_TEXT = 'Оставьте заявку на обратный звонок и мы свяжемся с Вами в течение 5 минут. Менеджер подберет для Вашей ситуации оптимальное решение.'

export class OrderModal {
    modal;
    isMobile = detectDevice();
    formData;
    constructor(proxyFormData) {
        this.formData = proxyFormData;
    }

    init(formContentSource) {
        this.generateModal(formContentSource);
    }
    generateModal(formContentSource) {
        const orderModal = document.createElement('div');
        orderModal.classList.add('order-modal');
        const orderModalFormSource = document.createElement('form');
        orderModalFormSource.classList.add('common-form', 'order-modal__form');
        orderModalFormSource.innerHTML = formContentSource.outerHTML;
        orderModalFormSource.setAttribute('id', 'order-modal-form');
        if (!this.isMobile) {
            const orderModalText = document.createElement('p');
            orderModalText.classList.add('order-modal__form-description');
            orderModalText.innerText = ORDER_MODAL_TEXT;
            orderModal.appendChild(orderModalText);
        }
        const formWrapper = document.createElement('div');
        formWrapper.classList.add('order-modal__form-wrapper');
        const closeButton = document.createElement('button');
        closeButton.classList.add('order-modal__close-button');
        closeButton.addEventListener('click', () => {
            this.hideModal();
        });
        formWrapper.appendChild(orderModalFormSource);
        formWrapper.appendChild(closeButton);
        orderModal.appendChild(formWrapper);
        const orderModalForm = new Form(orderModalFormSource, this.formData);
        orderModalForm.init(orderModalForm.phoneField);
        this.modal = orderModal;
        document.body.append(this.modal);
    }

    showModal() {
        this.modal.classList.add('order-modal_visible');
    }

    hideModal() {
        this.modal.classList.remove('order-modal_visible');
        document.body.style.removeProperty('overflow');
    }
}