export const MOSCOW_REGIONS = ['Балашиха', 'Богородский', 'Бронницы', 'Власиха', 'Волоколамский', 'Воскресенск', 'Восход', 'Дзержинский', 'Дмитровский', 'Долгопрудный', 'Домодедово', 'Дубна', 'Егорьевск', 'Жуковский', 'Зарайск', 'Звёздный городок', 'Истра', 'Кашира', 'Клин', 'Коломна', 'Королёв', 'Котельники', 'Красногорск', 'Краснознаменск', 'Ленинский', 'Лобня', 'Лосино-Петровский', 'Лотошино', 'Луховицы', 'Лыткарино', 'Люберцы', 'Можайский', 'Молодёжный', 'Мытищи', 'Наро-Фоминский', 'Одинцовский', 'Орехово-Зуевский', 'Павлово-Посадский', 'Подольск', 'Пушкинский', 'Раменский', 'Реутов', 'Рузский', 'Сергиево-Посадский', 'Серебряные Пруды', 'Серпухов', 'Солнечногорск', 'Ступино', 'Талдомский', 'Фрязино', 'Химки', 'Черноголовка', 'Чехов', 'Шатура', 'Шаховская', 'Щёлково', 'Электросталь']

export class RegionsDropdown {
    parentForm;
    dropdownWrapper;
    dropdownInput;
    selectedValue = null;
    regionsModal;
    regionsList;
    selectedWrapper;
    formData;
    constructor(parentForm, formData) {
        this.formData = formData;
        this.parentForm = parentForm;
    }

    get dropdownWrapper() {
        return this.parentForm.getElementById('common-form__location-wrapper');
    }

    set dropdownWrapper(value) {
        this.dropdownWrapper = value;
    }

    init() {
        this.dropdownWrapper = this.parentForm.querySelector('.common-form__location-wrapper');
        this.dropdownInput = this.dropdownWrapper.querySelector('div');
        this.dropdownInput.addEventListener('click', (e) => {
            e.preventDefault();
            this.showRegionsList();
        });
        this.createRegionsList();
        this.setSelectedValue(this.formData.region, true);
        this.listenRegionSelected();
        this.listenClickOutside();
        this.listenOnSelectedWrapperClick();
    }

    createRegionsList() {
        this.regionsModal = document.createElement('div');
        this.regionsModal.classList.add('regions-modal');
        const regionsListWrapper = document.createElement('div');
        regionsListWrapper.classList.add('regions-modal__list-wrapper');
        this.regionsList = MOSCOW_REGIONS.reduce((acc, curr) => {
            const regionItem = document.createElement('li');
            regionItem.classList.add('regions-dropdown__region-list-item');
            regionItem.innerHTML = curr;
            acc.appendChild(regionItem);
            return acc;
        }, document.createElement('ul'));
        this.regionsList.classList.add('regions-modal__regions-list');
        regionsListWrapper.appendChild(this.regionsList);

        this.selectedWrapper = document.createElement('div');
        this.selectedWrapper.classList.add('regions-modal__selected-wrapper');
        this.regionsModal.appendChild(regionsListWrapper);
    }

    setSelectedTemplate(selectedValueName) {
        this.selectedWrapper.insertAdjacentHTML('beforeend', `
            <span class="regions-modal__selected-value">Выбрано: ${selectedValueName}</span>
            <button class="regions-modal__select-button">ПОДТВЕРДИТЬ</button>
        `);
        this.regionsList.appendChild(this.selectedWrapper);
    }

    listenOnSelectedWrapperClick() {
        this.selectedWrapper.addEventListener('click', (e) => {
            if (e.target.classList.contains('regions-modal__select-button')) {
                this.hideRegionsList();
            }
        })
    }

    listenRegionSelected() {
        this.regionsList.addEventListener('click', (e) => {
            e.stopImmediatePropagation();
            if (e.target.classList.contains('regions-dropdown__region-list-item')) {
                this.setSelectedValue(e.target.innerText);
            }
        })
    }

    listenClickOutside() {
        this.regionsModal.addEventListener('click', () => this.hideRegionsList())
    }

    showRegionsList() {
        document.body.style.overflow = 'hidden';
        document.body.appendChild(this.regionsModal);
    }

    hideRegionsList() {
        this.regionsModal.remove();
        document.body.style.removeProperty('overflow');
    }

    setSelectedValue(selectedValue, init) {
        if (!init) {
            this.formData.region = selectedValue;
            this.selectedValue = selectedValue;
        } else {
            this.selectedValue = this.formData.region;
        }
        this.selectedWrapper.innerHTML = '';
        this.dropdownInput.innerText = this.selectedValue;
        this.regionsList.querySelectorAll('.regions-dropdown__region-list-item').forEach(regionItem => {
            if (this.selectedValue === regionItem.innerText) {
                regionItem.classList.add('regions-dropdown__region-list-item_selected');
            } else {
                regionItem.classList.remove('regions-dropdown__region-list-item_selected');
            }
        });
        this.setSelectedTemplate(this.selectedValue);
    }
}