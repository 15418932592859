import IMask from "imask";
import {RegionsDropdown} from "./regions-dropdown";
import {successMessage} from "./app";

const MASK_OPTIONS = {
    mask: '+{7}(000)000-00-00'
}

export class Form {
    anchorElement;
    regionsDropdown;
    formData;
    mask;
    constructor(anchorElement, formDataProxy) {
        this.anchorElement = anchorElement;
        this.formData = formDataProxy;
    }

    get phoneField() {
        return this.anchorElement.querySelector('.common-form__input_client-phone');
    }

    initPhoneMask(phoneField) {
        this.mask = IMask(phoneField, MASK_OPTIONS);
    }

    listenSubmit() {
        const submitButton = this.anchorElement.querySelector('.common-form__submit');
        submitButton.addEventListener('click', (e) => {
            e.preventDefault();
            submitButton.disabled = true;
            submitButton.innerText = 'Отправляем заявку...';
            const requestData = {
                name: this.formData.name,
                phone: this.mask.unmaskedValue,
                region: this.formData.region,
                service: this.formData.service,
            }

            $.ajax({
                type : "POST",
                url  : "../sender.php",
                data : requestData,
                success: () =>{
                    this.showSuccessModal(submitButton);
                    ym(97442966,'reachGoal','FORM_SEND_SUCCESSFULLY')
                }
            });
        })
    }

    showSuccessModal(button) {
        ym(97442966,'reachGoal','FORM_SEND_SUCCESSFULLY')
        document.body.style.overflow = 'hidden';
        successMessage.showModal();
        button.innerText = 'Заказать звонок';
        button.disabled = false;
    }

    init(phoneField) {
        this.regionsDropdown = new RegionsDropdown(this.anchorElement, this.formData);
        this.regionsDropdown.init();
        this.initPhoneMask(phoneField);
        this.initFormInputs();
        this.listenSubmit();
    }

    handleInputChange(event) {
        const name = event.target.getAttribute('name');
        this.formData[name] = event.target.value;
        this.mask.updateValue();
    }

    initFormInputs() {
        const formInputs = this.anchorElement.querySelectorAll('input');
        formInputs.forEach(input => {
            input.addEventListener('input', this.handleInputChange.bind(this))
        })
    }
}