export class Processes {
    processesRoot;
    lastScrollTop = 0;
    lastScrollDirection = 'down';

    constructor(processesRoot) {
        this.processesRoot = processesRoot;
        document.addEventListener("scroll", (e) => {
            const currentScrollTop = document.documentElement.scrollTop;
            if (currentScrollTop > this.lastScrollTop) {
                this.lastScrollDirection = 'down';
            } else {
                this.lastScrollDirection = 'top';
            }
            this.lastScrollTop = currentScrollTop < 0 ? 0 : currentScrollTop;
        });
    }

    listenDesktopProcessActive() {
        const processes = this.processesRoot.querySelectorAll('.process');
        processes.forEach(process => {
            process.addEventListener('mouseenter', (e) => {
                e.target.classList.add('process_selected');
            });
            process.addEventListener('mouseleave', (e) => {
                e.target.classList.remove('process_selected');
            })
        })
    }

    callBack = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('process_selected');
                if (this.lastScrollDirection === 'down') {
                    entry.target.classList.add('process_selected-from-top');
                } else {
                    entry.target.classList.add('process_selected-from-bottom');
                }
                entry.target.classList.remove('process_unselected-to-bottom');
                entry.target.classList.remove('process_unselected-to-top');
            } else {
                entry.target.classList.remove('process_selected');
                if (this.lastScrollDirection === 'down') {
                    entry.target.classList.add('process_unselected-to-bottom');
                } else {

                    entry.target.classList.add('process_unselected-to-top');

                }
                entry.target.classList.remove('process_selected-from-top');
                entry.target.classList.remove('process_selected-from-bottom');
            }
        })
    }

    options = {
        rootMargin: '0px 0px 75px 0px',
        threshold: 1,
    }

    observer = new IntersectionObserver(this.callBack, this.options);

    makeProcessesObserve() {
        const processes = this.processesRoot.querySelectorAll('.process');
        processes.forEach(process => this.observer.observe(process));
    }

}