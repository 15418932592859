import {Form} from './form';
import {Services, SERVICES_PERKS} from "./services";
import {OrderModal} from "./modal";
import {Reviews} from "./reviews";
import {Processes} from "./processes";
import {Header} from "./header";
import {detectDevice} from "./device-detector";
import {MOSCOW_REGIONS} from "./regions-dropdown";
import {SuccessMessage} from "./success";


// Init mobile check
export const isMobile = detectDevice();

// Success modal
export const successMessage = new SuccessMessage();

// Init header
const header = new Header(isMobile); // TODO: Add mode detector
header.initHeader();

const formData = {
    region: MOSCOW_REGIONS[0],
    name: '',
    phone: '',
    service: 'Откачать септик',
}
let proxy = new Proxy(formData, {
    set(target, property, value) {
        target[property] = value;
        if (property === 'region') {
            const dropdownsToChange = document.querySelectorAll('.common-form__input_location');
            dropdownsToChange.forEach(dropdown => dropdown.innerText = value);
        }

        else {
            const inputsToChange = document.querySelectorAll(`[name = ${property}]`);
            inputsToChange.forEach(inputToChange => inputToChange.value = value);
        }

        const submitButtons = document.querySelectorAll('.common-form__submit');
        const isPhoneValid = target['phone'].length >= 16
        submitButtons.forEach(button => {
            button.disabled = !isPhoneValid;
        })
        return true;
    },
    get(target, property) {
        return target[property];
    }
});


// Init main form
const formAnchor = document.getElementById('main-section-form');
const mainSectionForm = new Form(formAnchor, proxy);
mainSectionForm.init(mainSectionForm.phoneField);

// Init modal form
const orderModal = new OrderModal(proxy);
orderModal.init(formAnchor);

// Init services
const services = new Services(proxy);

// Init order modal callers
const modalCallers = document.querySelectorAll('.modal-caller');
modalCallers.forEach(caller => {
    caller.addEventListener('click', e => {
        e.preventDefault();
        document.body.style.overflow = 'hidden';
        orderModal.showModal();
    });
})

// Init reviews
const reviewsContentWrapper = document.querySelector('.reviews-section__content-wrapper');
const reviews = new Reviews(reviewsContentWrapper);
reviews.initReviews();

// Init processes
const processesContainer = document.querySelector('.processes__wrapper');
const processes = new Processes(processesContainer);
if (isMobile) {
    processes.makeProcessesObserve();
} else {
    processes.listenDesktopProcessActive();
}

// Init order section form
const orderSectionFormAnchor = document.getElementById('order-section-form');
const orderSectionForm = new Form(orderSectionFormAnchor, proxy);
orderSectionForm.init(orderSectionForm.phoneField);

// Smooth scroll to anchors
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});
