export const REVIEWS = [
    {
        authorName: 'Mariya',
        reviewText: 'Приехали вовремя. Откачали септик, продули канализацию. Всё сделали качественно и быстро. Спасибо!',
        reviewImage: 'review_1.jpg'
    },
    {
        authorName: 'Николай М.',
        reviewText: 'Выезд был в Железнодорожный. Быстро приехали, быстро откачали, быстро уехали. Клроче, молодцы. Заодно договорились об услуги чистки септика.',
        reviewImage: 'review_2.jpg'
    },
    {
        authorName: 'Ольга Г.',
        reviewText: 'Большое спасибо менеджеру Анастасии! Приехали как договорились, сделали все чисто и шустро. Будем советовать соседям))',
        reviewImage: 'review_3.jpg'
    },
    {
        authorName: 'Антон',
        reviewText: 'Все быстро. По цене сошлись, работают аккуратно. Лишних "допов" не впаривали. Желаю успехов в бизнесе и буду обращаться еще.',
        reviewImage: 'review_4.jpg'
    },
    {
        authorName: 'Артем',
        reviewText: 'Приехали в течения дня услугой я доволен- отдал 5500',
        reviewImage: 'review_5.jpg'
    },
    {
        authorName: 'Карина М.',
        reviewText: 'Второй раз заказывала откачу, всё быстро и качественно.Рекомендую',
        reviewImage: 'review_7.jpg'
    },
    {
        authorName: 'Руслан',
        reviewText: 'Позвонил в компанию ,ответили сразу ,записали на 22 апреля ,через 5 мин перезвонили и сделали запись в этот же день .Приехали быстро ,аккуратно откачали яму, вместе с илом .Все устроило ,теперь только к этим ребятам .',
        reviewImage: 'review_6.jpg'
    },
    {
        authorName: 'Алиса Д.',
        reviewText: 'Заказываю откачку септика уже не в первый раз, но в этот раз почему-то водитель не позвонил за час, хотя просили. В остальном - никаких вопросов, услуга как услуга.',
        reviewImage: 'review_8.jpg'
    },
    {
        authorName: 'Оксана',
        reviewText: 'приехали в обговоренное время. Быстро все очистили. единственное,что понадобилось дополнительно - вода. и я неверно измеряла расстояние до септика.но выход нашли и всё сделали!спасибо!',
        reviewImage: 'review_9.jpg'
    },

]

import * as urls from "../assets/img/*";

export class Reviews {
    currentPage = 0;
    pageSize = 3;
    reviewsPlacement;
    increasePageButton;

    constructor(reviewsPlacement) {
        this.reviewsPlacement = reviewsPlacement.querySelector('.reviews-section__reviews-list');
        this.increasePageButton = reviewsPlacement.querySelector('.reviews-section__load-more-button');
    }

    get reviewStars() {
        return `
            <div class="review__stars-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8.77243 0.829716L10.577 4.48629C10.7025 4.7406 10.9451 4.91682 11.2258 4.95754L15.2612 5.54394C15.9681 5.64674 16.2501 6.51516 15.7388 7.01345L12.8188 9.85967C12.6159 10.0576 12.5231 10.3429 12.5712 10.6223L13.2604 14.6413C13.3812 15.3452 12.6423 15.8819 12.0101 15.5498L8.40097 13.6524C8.14999 13.5206 7.84994 13.5206 7.59896 13.6524L3.98978 15.5498C3.35766 15.8822 2.61874 15.3452 2.73955 14.6413L3.42875 10.6223C3.47681 10.3429 3.38403 10.0576 3.18111 9.85967L0.261123 7.01345C-0.250182 6.51482 0.0318367 5.6464 0.73872 5.54394L4.7741 4.95754C5.05478 4.91682 5.29742 4.7406 5.42291 4.48629L7.2275 0.829716C7.54323 0.189248 8.45637 0.189248 8.77243 0.829716Z" fill="#ED8A19"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8.77243 0.829716L10.577 4.48629C10.7025 4.7406 10.9451 4.91682 11.2258 4.95754L15.2612 5.54394C15.9681 5.64674 16.2501 6.51516 15.7388 7.01345L12.8188 9.85967C12.6159 10.0576 12.5231 10.3429 12.5712 10.6223L13.2604 14.6413C13.3812 15.3452 12.6423 15.8819 12.0101 15.5498L8.40097 13.6524C8.14999 13.5206 7.84994 13.5206 7.59896 13.6524L3.98978 15.5498C3.35766 15.8822 2.61874 15.3452 2.73955 14.6413L3.42875 10.6223C3.47681 10.3429 3.38403 10.0576 3.18111 9.85967L0.261123 7.01345C-0.250182 6.51482 0.0318367 5.6464 0.73872 5.54394L4.7741 4.95754C5.05478 4.91682 5.29742 4.7406 5.42291 4.48629L7.2275 0.829716C7.54323 0.189248 8.45637 0.189248 8.77243 0.829716Z" fill="#ED8A19"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8.77243 0.829716L10.577 4.48629C10.7025 4.7406 10.9451 4.91682 11.2258 4.95754L15.2612 5.54394C15.9681 5.64674 16.2501 6.51516 15.7388 7.01345L12.8188 9.85967C12.6159 10.0576 12.5231 10.3429 12.5712 10.6223L13.2604 14.6413C13.3812 15.3452 12.6423 15.8819 12.0101 15.5498L8.40097 13.6524C8.14999 13.5206 7.84994 13.5206 7.59896 13.6524L3.98978 15.5498C3.35766 15.8822 2.61874 15.3452 2.73955 14.6413L3.42875 10.6223C3.47681 10.3429 3.38403 10.0576 3.18111 9.85967L0.261123 7.01345C-0.250182 6.51482 0.0318367 5.6464 0.73872 5.54394L4.7741 4.95754C5.05478 4.91682 5.29742 4.7406 5.42291 4.48629L7.2275 0.829716C7.54323 0.189248 8.45637 0.189248 8.77243 0.829716Z" fill="#ED8A19"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8.77243 0.829716L10.577 4.48629C10.7025 4.7406 10.9451 4.91682 11.2258 4.95754L15.2612 5.54394C15.9681 5.64674 16.2501 6.51516 15.7388 7.01345L12.8188 9.85967C12.6159 10.0576 12.5231 10.3429 12.5712 10.6223L13.2604 14.6413C13.3812 15.3452 12.6423 15.8819 12.0101 15.5498L8.40097 13.6524C8.14999 13.5206 7.84994 13.5206 7.59896 13.6524L3.98978 15.5498C3.35766 15.8822 2.61874 15.3452 2.73955 14.6413L3.42875 10.6223C3.47681 10.3429 3.38403 10.0576 3.18111 9.85967L0.261123 7.01345C-0.250182 6.51482 0.0318367 5.6464 0.73872 5.54394L4.7741 4.95754C5.05478 4.91682 5.29742 4.7406 5.42291 4.48629L7.2275 0.829716C7.54323 0.189248 8.45637 0.189248 8.77243 0.829716Z" fill="#ED8A19"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8.77243 0.829716L10.577 4.48629C10.7025 4.7406 10.9451 4.91682 11.2258 4.95754L15.2612 5.54394C15.9681 5.64674 16.2501 6.51516 15.7388 7.01345L12.8188 9.85967C12.6159 10.0576 12.5231 10.3429 12.5712 10.6223L13.2604 14.6413C13.3812 15.3452 12.6423 15.8819 12.0101 15.5498L8.40097 13.6524C8.14999 13.5206 7.84994 13.5206 7.59896 13.6524L3.98978 15.5498C3.35766 15.8822 2.61874 15.3452 2.73955 14.6413L3.42875 10.6223C3.47681 10.3429 3.38403 10.0576 3.18111 9.85967L0.261123 7.01345C-0.250182 6.51482 0.0318367 5.6464 0.73872 5.54394L4.7741 4.95754C5.05478 4.91682 5.29742 4.7406 5.42291 4.48629L7.2275 0.829716C7.54323 0.189248 8.45637 0.189248 8.77243 0.829716Z" fill="#ED8A19"/>
                </svg>
            </div>
        `
    }

    initReviews() {
        this.loadReviewsPage(0);
        this.increasePageButton.addEventListener('click', () => {
            this.loadReviewsPage(this.currentPage + 1);
            const hasNext = REVIEWS.length / this.pageSize > this.currentPage + 1;
            if (!hasNext) {
                this.increasePageButton.remove();
            }
        })
    }

    loadReviewsPage(pageNumber) {
        if (pageNumber < this.currentPage) {
            return;
        }

        this.currentPage = pageNumber;
        for (let i = 0; i < this.pageSize; i++) {
            const reviewNumber = pageNumber * this.pageSize + i;
            const reviewData = REVIEWS[reviewNumber];
            const block = this.createReviewBlock(reviewData.authorName, reviewData.reviewText, reviewData.reviewImage);
            this.reviewsPlacement.appendChild(block);
        }
    }

    createReviewBlock(authorName, reviewText, reviewImage) {
        const reviewBlock = document.createElement('div');
        reviewBlock.classList.add('review');
        let reviewImageWrapper = null;
        if (reviewImage) {
            reviewImageWrapper = document.createElement('div');
            reviewImageWrapper.classList.add('review__image-wrapper');
            const reviewImageElement = document.createElement('img');
            reviewImageElement.classList.add('review__image');
            reviewImageElement.setAttribute('src', urls[`${reviewImage}`]);
            reviewImageWrapper.appendChild(reviewImageElement);
        }

        const reviewTextElement = document.createElement('p');
        reviewTextElement.classList.add('review__text');
        reviewTextElement.innerText = reviewText;
        const reviewAuthorWrapper = document.createElement('div');
        reviewAuthorWrapper.classList.add('review__author-wrapper');
        reviewAuthorWrapper.innerHTML = `
            <span class="review__author">${authorName}</span>
            ${this.reviewStars}
        `;
        if (reviewImageWrapper) {
            reviewBlock.appendChild(reviewImageWrapper);
        }
        reviewBlock.appendChild(reviewTextElement);
        reviewBlock.appendChild(reviewAuthorWrapper);
        return reviewBlock;
    }
}