const _temp0 = require("../assets/img/cars-in-mask.png");
const _temp1 = require("../assets/img/feature_1.png");
const _temp2 = require("../assets/img/feature_2.png");
const _temp3 = require("../assets/img/feature_3.jpg");
const _temp4 = require("../assets/img/first-screen_background.jpeg");
const _temp5 = require("../assets/img/first-screen_background.jpg");
const _temp6 = require("../assets/img/first-screen_truck.png");
const _temp7 = require("../assets/img/first-section_third-feature.jpg");
const _temp8 = require("../assets/img/moscow.png");
const _temp9 = require("../assets/img/order_background.jpg");
const _temp10 = require("../assets/img/review_1.jpg");
const _temp11 = require("../assets/img/review_1.svg");
const _temp12 = require("../assets/img/review_2.jpg");
const _temp13 = require("../assets/img/review_3.jpg");
const _temp14 = require("../assets/img/review_4.jpg");
const _temp15 = require("../assets/img/review_5.jpg");
const _temp16 = require("../assets/img/review_6.jpg");
const _temp17 = require("../assets/img/review_7.jpg");
const _temp18 = require("../assets/img/review_8.jpg");
const _temp19 = require("../assets/img/review_9.jpg");
const _temp20 = require("../assets/img/service_1.jpg");
const _temp21 = require("../assets/img/service_10.jpg");
const _temp22 = require("../assets/img/service_2.jpg");
const _temp23 = require("../assets/img/service_3.jpg");
const _temp24 = require("../assets/img/service_4.jpg");
const _temp25 = require("../assets/img/service_5.jpg");
const _temp26 = require("../assets/img/service_6.jpg");
const _temp27 = require("../assets/img/service_7.jpg");
const _temp28 = require("../assets/img/service_8.jpg");
const _temp29 = require("../assets/img/service_9.jpg");
const _temp30 = require("../assets/img/work_1.jpg");
const _temp31 = require("../assets/img/work_2.jpg");
const _temp32 = require("../assets/img/work_3.jpg");
const _temp33 = require("../assets/img/work_4.jpg");
const _temp34 = require("../assets/img/work_5.jpg");
const _temp35 = require("../assets/img/work_6.jpg");
const _temp36 = require("../assets/img/work_7.jpg");
const _temp37 = require("../assets/img/work_8.jpg");
const _temp38 = require("../assets/img/work_9.jpg");
module.exports = {
  "cars-in-mask.png": _temp0,
  "feature_1.png": _temp1,
  "feature_2.png": _temp2,
  "feature_3.jpg": _temp3,
  "first-screen_background.jpeg": _temp4,
  "first-screen_background.jpg": _temp5,
  "first-screen_truck.png": _temp6,
  "first-section_third-feature.jpg": _temp7,
  "moscow.png": _temp8,
  "order_background.jpg": _temp9,
  "review_1.jpg": _temp10,
  "review_1.svg": _temp11,
  "review_2.jpg": _temp12,
  "review_3.jpg": _temp13,
  "review_4.jpg": _temp14,
  "review_5.jpg": _temp15,
  "review_6.jpg": _temp16,
  "review_7.jpg": _temp17,
  "review_8.jpg": _temp18,
  "review_9.jpg": _temp19,
  "service_1.jpg": _temp20,
  "service_10.jpg": _temp21,
  "service_2.jpg": _temp22,
  "service_3.jpg": _temp23,
  "service_4.jpg": _temp24,
  "service_5.jpg": _temp25,
  "service_6.jpg": _temp26,
  "service_7.jpg": _temp27,
  "service_8.jpg": _temp28,
  "service_9.jpg": _temp29,
  "work_1.jpg": _temp30,
  "work_2.jpg": _temp31,
  "work_3.jpg": _temp32,
  "work_4.jpg": _temp33,
  "work_5.jpg": _temp34,
  "work_6.jpg": _temp35,
  "work_7.jpg": _temp36,
  "work_8.jpg": _temp37,
  "work_9.jpg": _temp38
}