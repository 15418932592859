import {isMobile} from "./app";

export const SERVICES_PERKS = {
    1: {
        items: ['Длина шланга до 100 метров', 'Промывка дна септика', 'Вывоз ила, тяжелых отложений и песка'],
        price: 3500,
    },
    2: {
        items: ['Длина шланга до 100 метров', 'Промывка дна септика', 'Вывоз ила, тяжелых отложений и песка'],
        price: 3500,
    },
    3: {
        items: ['Длина шланга до 100 метров', 'Вывоз воды из подвала на утилизацию', 'Используется ассенизатор или илосос'],
        price: 3500,
    },
    4: {
        items: ['Длина шланга до 40 метров', 'Вывоз ЖБО на утилизацию', 'Используется ассенизатор или илосос'],
        price: 2000,
    },
    5: {
        items: ['Длина шланга до 60 метров', 'Вывоз ЖБО на утилизацию', 'Используется ассенизатор или илосос'],
        price: 3500,
    },
    6: {
        items: ['Длина шланга до 100 метров', 'Полностью заправленная машина', 'Привоз технической воды и пр.'],
        price: 7000,
    },
    7: {
        items: ['Длина шланга до 100 метров', 'Промывка емкостей', 'Утилизация ЖБО до 4 класса опасности'],
        price: 7000,
    },
    8: {
        items: ['Длина шланга до 250 метров', 'Трубы от 5м в длину и 50мм в диаметре', 'Промывка, очистка от жировых отложений'],
        price: 4500,
    },
    9: {
        items: ['Сервисное обслуживание', 'Диагностика электрооборудования', 'Консервация и ремонт любой сложности'],
        price: 5500,
    },
    10: {
        items: ['Применяется совместно с работой илососа', 'Прочистка, вывоз и утилизация ЖБО', 'Услуги илососа оплачиваются отдельно'],
        price: 10000,
    }

}

import * as urls from "../assets/img/*";

export class Services {
    section;
    itemsList;
    activeItemIndex;
    perksPlacement;
    selectedItemName;
    formData;
    serviceExpanded = !isMobile;
    selectedItemWrapper;
    constructor(proxyFormData) {
        this.formData = proxyFormData;
        this.init();
    }

    get itemsList() {
        return this.itemsList;
    }

    setActiveItem(newActiveItem) {
        this.activeItemIndex = newActiveItem.dataset.index;
        this.itemsList.forEach(item => {
            if (item.dataset.index !== newActiveItem.dataset.index) {
                item.classList.remove('service-section__service-item_selected');
            } else {
                item.classList.add('service-section__service-item_selected');
                this.selectedItemName = item.innerText;
                this.setSelectedServiceContent(this.activeItemIndex);
                this.formData['service'] = item.innerText;
            }
        })
    }

    init() {
        this.section = document.querySelector('.service-section');
        this.itemsList = this.section.querySelectorAll('.service-section__service-item');
        this.perksPlacement = this.section.querySelector('.service-section__selected-service-content-wrapper');
        this.selectedItemWrapper = this.section.querySelector('.service-section__selected-service-description');

        this.setActiveItem(this.itemsList[0]);
        this.setSelectedServiceContent(this.itemsList[0].dataset.index);

        if (isMobile) {
            this.serviceExpanded = false;
            const expandButton = document.createElement('button');
            expandButton.classList.add('service-section__toggle-mobile-service-button');
            expandButton.innerText = this.serviceExpanded ? 'Свернуть' : 'Развернуть';
            expandButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.serviceExpanded = !this.serviceExpanded;
                const perksWrapper = this.section.querySelector('.service-section__perks-list-wrapper');
                if (this.serviceExpanded) {
                    perksWrapper.classList.add('service-section__perks-list-wrapper_expanded')
                    expandButton.innerText = 'Свернуть';
                } else {
                    perksWrapper.classList.remove('service-section__perks-list-wrapper_expanded');
                    expandButton.innerText = 'Развернуть';
                }
            });
            this.selectedItemWrapper.appendChild(expandButton);
        }

        this.itemsList.forEach(item => {
            item.addEventListener('click', () => this.setActiveItem(item))
        })
    }

    getSelectedServiceTemplate(serviceItem, index) {
        const perksList = serviceItem.items.reduce((acc, curr) => {
            const perkItem = document.createElement('li');
            perkItem.classList.add('service-section__perks-list-item');
            perkItem.innerHTML = curr;
            acc.appendChild(perkItem);
            return acc;
        }, document.createElement('ul'));
        perksList.classList.add('service-section__perks-list');

        const perksListWrapper = document.createElement('div');
        perksListWrapper.classList.add('service-section__perks-list-wrapper');
        const perksListImageWrapper = document.createElement('div');
        perksListImageWrapper.classList.add('service-section__perks-list-image-wrapper');
        const perksListImage = document.createElement('img');
        perksListImage.classList.add('service-section__perks-list-image');
        perksListImage.setAttribute('src', urls[`service_${index}.jpg`]);
        perksListImageWrapper.appendChild(perksListImage);
        perksListWrapper.appendChild(perksListImageWrapper);
        perksListWrapper.appendChild(perksList);

        const selectedService = document.createElement('span');
        selectedService.classList.add('service-section__selected-service-value');
        selectedService.innerText = `Услуга: ${this.selectedItemName}`

        const price = document.createElement('h4');
        price.classList.add('service-section__selected-service-price');
        price.innerHTML = `от ${serviceItem.price} рублей`;
        return selectedService.outerHTML + perksListWrapper.outerHTML + price.outerHTML;
    }

    setSelectedServiceContent(selectedIndex) {
        const perkContent = this.getSelectedServiceTemplate(SERVICES_PERKS[selectedIndex], selectedIndex);
        this.perksPlacement.innerHTML = perkContent;
    }

}