export class SuccessMessage {
    successModal;
    constructor() {
        this.initModal();
    }

    initModal() {
        const successModal = document.createElement('div');
        successModal.classList.add('success-modal');
        const messageWrapper = document.createElement('div');
        messageWrapper.classList.add('success-modal__message-wrapper');
        const title = document.createElement('h3');
        title.classList.add('success-modal__title');
        title.innerText = 'Заявка успешно отправлена';
        const message = document.createElement('p');
        message.classList.add('success-modal__message');
        message.innerText = 'Скоро с Вами свяжется менеджер для уточнения деталей.';
        const closeButton = document.createElement('button');
        closeButton.classList.add('success-modal__close-button');
        closeButton.addEventListener('click', () => {
            this.hideModal();
        });
        messageWrapper.appendChild(closeButton);
        messageWrapper.appendChild(title);
        messageWrapper.appendChild(message);
        successModal.appendChild(messageWrapper);
        this.successModal = successModal;
    }

    showModal() {
        document.body.append(this.successModal);
    }

    hideModal() {
        document.body.style.removeProperty('overflow');
        this.successModal.remove();
        const orderModal = document.querySelector('.order-modal');
        if (!orderModal) {
            return;
        }
        orderModal.classList.remove('order-modal_visible');
    }
}