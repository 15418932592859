export class Header {
    isMobile;
    selectedHeader;
    scrollTimer = null;
    constructor(deviceMode) {
        this.isMobile =  deviceMode;
    }

    initHeader() {
        if (this.isMobile) {
            this.selectedHeader = document.querySelector('.header_mobile');
            const hiddenHeader = document.querySelector('.header_desktop');
            hiddenHeader.classList.add('header_hidden');
        } else {
            this.selectedHeader = document.querySelector('.header_desktop');
            const hiddenHeader = document.querySelector('.header_mobile');
            hiddenHeader.classList.add('header_hidden');
        }

        this.selectedHeader.classList.add('header_placed');

        document.addEventListener('scroll', () => {
            const scrollPosition = document.documentElement.scrollTop;
            if (scrollPosition > 300) {
                if (this.scrollTimer !== null) {
                    this.selectedHeader.classList.remove('header_placed');
                    clearTimeout(this.scrollTimer);
                }
                this.scrollTimer = setTimeout(() => {
                    this.selectedHeader.classList.add('header_placed');
                }, 150);
            } else {
                this.selectedHeader.classList.add('header_placed');
            }
        }, false);
    }
}